import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import Code from '@primer/gatsby-theme-doctocat/src/components/code';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <p>{`Use form controls when requiring data input from the user. For example, creating a new repo configuring settings, and logging in.`}</p>
    <p>{`This component is a helper component to keep layouts consistent and ensure the correct ARIA attributes are set. You can find some of Primer's form components listed in the `}<a parentName="p" {...{
        "href": "#related-links"
      }}>{`Related links`}</a>{` section.`}</p>
    <h2>{`Anatomy`}</h2>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/2313998/171692166-43d45c4b-509a-4f68-9e8b-1577658b493e.png",
        "alt": "diagrams labeling the anatomy of a text field and a checkbox field"
      }}></img></p>
    <p>{`Form controls allow users to provide data. At a minimum, they include an input and label. They may also include a caption and required field indicator.`}</p>
    <p>{`To learn more about anatomy, input methods, forms structure, validation, and more, please refer to our `}<a parentName="p" {...{
        "href": "/ui-patterns/forms/overview"
      }}>{`Forms guidance`}</a>{`.`}</p>
    <h2>{`Accessibility`}</h2>
    <h3>{`Required Fields`}</h3>
    <p>{`When using symbols -e.g., an asterisk (*)- to indicate particular fields are required within a form, consider adding a "Required fields are marked with an asterisk (`}{`*`}{`)" message at the top of the form for extra clarity.`}</p>
    <p>{`When marking a control as required, opt to mark the form control as required instead of the input contained within it. The form control will ensure that the required state is passed down to the input element.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
        <Code className="language-jsx" mdxType="Code">{`
      <FormControl required>
  <FormControl.Label>
    Form Input Label
  </FormControl.Label>
  <Checkbox />
</FormControl>
    `}</Code>
    <Caption mdxType="Caption">Set the required field in the form control</Caption>
  </Do>
  <Dont mdxType="Dont">
    <Code className="language-jsx" mdxType="Code">{`
      <FormControl>
  <FormControl.Label>
    Form Input Label
  </FormControl.Label>
  <Checkbox required />
</FormControl>
    `}</Code>
    <Caption mdxType="Caption">Don’t set the required field on the underlying input</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="FormControl" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/forms/overview"
        }}>{`Forms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/checkbox"
        }}>{`Checkbox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/checkbox-group"
        }}>{`Checkbox group`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio"
        }}>{`Radio`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`Radio group`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/text-input"
        }}>{`Text input`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/textarea"
        }}>{`Textarea`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/select"
        }}>{`Select`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      